import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from '~/src/theme'

type ProgressBarVariant = 'purple' | 'green'

interface ProgressBarProps {
  variant?: ProgressBarVariant
  target: number
  value: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ variant = 'purple', value, target }) => {
  return (
    <Bar>
      <Value variant={variant} percentage={(value / target) * 100} />
    </Bar>
  )
}

const Bar = styled.div`
  height: 5px;
  background: ${theme.colors.neutral[200]};
  border-radius: 4px;
`

const Value = styled.div<{ variant: ProgressBarVariant; percentage: number }>`
  height: 5px;
  width: ${(props) => props.percentage}%;

  ${(props) =>
    props.variant === 'purple' &&
    css`
      background: ${theme.colors.purple.gradient};
    `}

  ${(props) =>
    props.variant === 'green' &&
    css`
      background: ${theme.colors.green.gradient};
    `}
`

export { ProgressBar }

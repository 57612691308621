const theme = {
  fontFamily: {
    sans: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
  },

  fontSizes: {
    sm: 10,
    regular: 14,
    lg: 16,
    xl: 22,
    sub: 35,
    heading: 55
  },

  colors: {
    text: '#42474A',
    bg: '#F4F5F9',

    neutral: {
      50: '#fff',
      100: '#F4F5F9',
      200: '#ECECEC',
      // 300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#A2A4B9',
      600: '#42474A',
      700: '#888CB7',
      // 800: '#27272a',
      900: '#000000'
    },

    purple: {
      gradient: 'linear-gradient(90deg, #713BDB 0%, #A886E9 111.47%)',
      500: '#713BDB'
    },

    green: {
      gradient: 'linear-gradient(90deg, #33D69F 0%, #50EDB8 111.47%);',
      500: '#50EDB8'
    },

    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a'
    },

    red: {
      500: '#ef4444'
    },

    yellow: {
      500: '#EAB308'
    }
  },

  breakpoints: {
    md: `(min-width: 1020px)`
  },

  zIndex: {
    megaBannerIllustration: 98,
    megaBannerBg: 99,
    megaBannerContent: 100,
    sidebar: 1000
  }
}

export { theme }

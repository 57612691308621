import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { theme } from '~/src/theme'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: ${theme.fontFamily.sans};
    font-size: ${theme.fontSizes.regular}px;
    color: ${theme.colors.neutral[700]};
    background: ${theme.colors.neutral[100]};
  }
`

export { GlobalStyle }

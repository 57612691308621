import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom/client'

import 'sanitize.css'
import logo from '~/src/images/logo.png'
import avatar from '~/src/images/avatar.png'
import illustration from '~/src/images/banner-illustration.svg'
import banner from '~/src/images/banner-bg.svg'
import styled, { css } from 'styled-components'
import { theme } from '~/src/theme'
import { useOutsideClick } from '~/src/hooks'
import { Button, GlobalStyle, Icon, ProgressBar } from '~/src/components'

const App = () => {
  const [open, setOpen] = useState(false)

  const sidebarRef = useRef()

  const navButtonRef = useRef()

  useOutsideClick([sidebarRef, navButtonRef], () => {
    if (open) setOpen(false)
  })

  const handleToggleButton = () => {
    setOpen(!open)
  }

  const stats = [
    {
      policy: 400,
      claim: 800,
      target: 1000
    },
    {
      policy: 1100,
      claim: 1400,
      target: 2500
    },
    {
      policy: 1100,
      claim: 1400,
      target: 2500
    }
  ]

  return (
    <>
      <Main>
        <MobileNav>
          <img src={logo} alt="Insurance Portal Logo" />
          <MobileNavButton type="button" onClick={handleToggleButton} ref={navButtonRef}>
            <Icon name="menu" />
          </MobileNavButton>
        </MobileNav>

        <Nav>
          <NavItem href="#">Overview</NavItem>
          <NavItem href="#">Policy</NavItem>
          <NavItem href="#" active>
            Reports
          </NavItem>
        </Nav>

        <MainContent>
          <MegaBanner>
            <MegaBannerBg />

            <MegaBannerIllustration src={illustration} />

            <MegaBannerText>
              <MegaBannerTextHeading>20% safe on your first account</MegaBannerTextHeading>
              <MegaBannerTextCodeContainer>
                <MegaBannerTextCode>Newbie20</MegaBannerTextCode>

                <MegaBannerTextCodeCopy type="button">Copy Code</MegaBannerTextCodeCopy>
              </MegaBannerTextCodeContainer>
            </MegaBannerText>
          </MegaBanner>

          <Stats>
            {stats.map((stat, i) => (
              <StatsColumn key={i}>
                <StatsItem>
                  <StatsItemValue>
                    {stat.target}
                    <StatsItemValueCurrency>$</StatsItemValueCurrency>
                  </StatsItemValue>

                  <StatsItemHeading>Total Value of Disbursement</StatsItemHeading>

                  <StatsItemProgress>
                    <StatsItemProgressLabel>
                      <StatsItemProgressLabelText>Policy</StatsItemProgressLabelText>

                      <StatsItemProgressLabelValue>
                        400
                        <StatsItemProgressLabelValueCurrency>$</StatsItemProgressLabelValueCurrency>
                      </StatsItemProgressLabelValue>
                    </StatsItemProgressLabel>

                    <ProgressBar value={stat.policy} target={stat.target} variant="green" />
                  </StatsItemProgress>

                  <StatsItemProgress>
                    <StatsItemProgressLabel>
                      <StatsItemProgressLabelText>Claim</StatsItemProgressLabelText>

                      <StatsItemProgressLabelValue>
                        800
                        <StatsItemProgressLabelValueCurrency>$</StatsItemProgressLabelValueCurrency>
                      </StatsItemProgressLabelValue>
                    </StatsItemProgressLabel>

                    <ProgressBar value={stat.claim} target={stat.target} />
                  </StatsItemProgress>
                </StatsItem>
              </StatsColumn>
            ))}
          </Stats>
        </MainContent>
      </Main>

      <Sidebar open={open} ref={sidebarRef}>
        <Logo>
          <LogoImg src={logo} alt="Insurance Portal Logo" />
          <LogoText>Insurance Portal</LogoText>
        </Logo>
        <UserInfo>
          <UserInfoAvatar src={avatar} alt="Rusalba Ruis's Avatar" />
          <UserInfoWelcome>Hello, Rusalba Ruiz!</UserInfoWelcome>
          <UserInfoSubtext>Welcome back to your Insurance Portal</UserInfoSubtext>
          <UserInfoPlan>
            Your Plan: <UserInfoPlanStatus>Free</UserInfoPlanStatus>
          </UserInfoPlan>
        </UserInfo>
        <PlanButton>
          <Button icon="plus-sm">Create new plan</Button>
        </PlanButton>

        <SidebarMenu>
          <SidebarMenuItem href="#">
            <SidebarMenuItemIcon>
              <Icon name="view-gridOutline" />
            </SidebarMenuItemIcon>
            <SidebarMenuItemText>Overview</SidebarMenuItemText>
          </SidebarMenuItem>

          <SidebarMenuItem href="#">
            <SidebarMenuItemIcon>
              <Icon name="document-search" />
            </SidebarMenuItemIcon>
            <SidebarMenuItemText>Policy</SidebarMenuItemText>
          </SidebarMenuItem>

          <SidebarMenuItem href="#" active>
            <SidebarMenuItemIcon>
              <Icon name="presentation-chart-line" />
            </SidebarMenuItemIcon>
            <SidebarMenuItemText>Reports</SidebarMenuItemText>
          </SidebarMenuItem>

          <SidebarMenuItem href="#">
            <SidebarMenuItemIcon>
              <Icon name="identification" />
            </SidebarMenuItemIcon>
            <SidebarMenuItemText>Company</SidebarMenuItemText>
          </SidebarMenuItem>

          <SidebarMenuItem href="#">
            <SidebarMenuItemIcon>
              <Icon name="user" />
            </SidebarMenuItemIcon>
            <SidebarMenuItemText>User Account</SidebarMenuItemText>
          </SidebarMenuItem>

          <SidebarMenuItem href="#">
            <SidebarMenuItemIcon>
              <Icon name="newspaper" />
            </SidebarMenuItemIcon>
            <SidebarMenuItemText>History</SidebarMenuItemText>
          </SidebarMenuItem>
        </SidebarMenu>

        <SidebarMenu push>
          <SidebarMenuItem href="#">
            <SidebarMenuItemIcon>
              <Icon name="logout" />
            </SidebarMenuItemIcon>
            <SidebarMenuItemText>Logout</SidebarMenuItemText>
          </SidebarMenuItem>
        </SidebarMenu>
      </Sidebar>
      <GlobalStyle />
    </>
  )
}

const Main = styled.div`
  min-height: 100vh;
  height: 100%;
  background: ${theme.colors.neutral[100]};

  @media ${theme.breakpoints.md} {
    padding-left: 306px;
  }
`

const MainContent = styled.div`
  max-width: 1030px;
  padding: 16px;

  @media ${theme.breakpoints.md} {
    padding: 24px;
  }
`

const MegaBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  height: 320px;
  background: #f8f9ff;

  @media ${theme.breakpoints.md} {
    justify-content: initial;
  }
`

const MegaBannerBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-position: bottom;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  z-index: ${theme.zIndex.megaBannerBg};
  pointer-events: none;
`

const MegaBannerIllustration = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  object-fit: cover;
  margin-right: 32px;
  z-index: ${theme.zIndex.megaBannerIllustration};

  @media ${theme.breakpoints.md} {
    display: block;
    width: block;
  }
`

const MegaBannerText = styled.div`
  position: relative;
  text-align: center;
  z-index: ${theme.zIndex.megaBannerContent};

  @media ${theme.breakpoints.md} {
    margin-left: auto;
    width: 560px;
  }
`

const MegaBannerTextHeading = styled.h1`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: ${theme.fontSizes.sub}px;
  font-weight: 500;
  color: ${theme.colors.neutral[600]};
`

const MegaBannerTextCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MegaBannerTextCode = styled.div`
  margin-right: 16px;
  padding: 8px;
  font-size: ${theme.fontSizes.xl}px;
  color: ${theme.colors.neutral[600]};
  border: 1px dashed ${theme.colors.neutral[600]};
  border-radius: 4px;
`

const MegaBannerTextCodeCopy = styled.button`
  display: inline-block;
  padding: 0;
  font-size: ${theme.fontSizes.xl}px;
  font-weight: 600;
  background: transparent;
  font-family: ${theme.fontFamily.sans};
  color: ${theme.colors.neutral[600]};
  border: 0;
  outline: 0;
  cursor: pointer;
`

const Stats = styled.div`
  @media ${theme.breakpoints.md} {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
  }
`

const StatsColumn = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media ${theme.breakpoints.md} {
    width: 33.33%;
    padding-left: 8px;
    padding-right: 8px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const StatsItem = styled.div`
  padding: 32px 24px;
  background: ${theme.colors.neutral[50]};
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.06);
`

const StatsItemValue = styled.div`
  display: flex;
  margin-bottom: 8px;
  color: ${theme.colors.neutral[600]};
  font-size: ${theme.fontSizes.heading}px;
  font-weight: 400;
`

const StatsItemHeading = styled.h4`
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: ${theme.fontSizes.lg}px;
  color: ${theme.colors.neutral[600]};
`

const StatsItemProgress = styled.div`
  &:not(:last-child) {
    margin-bottom: 48px;
  }
`

const StatsItemProgressLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StatsItemProgressLabelText = styled.div`
  font-size: ${theme.fontSizes.lg}px;
  color: ${theme.colors.neutral[600]};
`

const StatsItemProgressLabelValue = styled.div`
  display: flex;
  font-size: ${theme.fontSizes.xl}px;
  font-weight: 400;
  color: ${theme.colors.neutral[600]};
`

const StatsItemProgressLabelValueCurrency = styled.div`
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 400;
`

const StatsItemValueCurrency = styled.span`
  font-size: ${theme.fontSizes.sub}px;
  color: ${theme.colors.purple[500]};
`

const MobileNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: ${theme.colors.neutral[50]};
  border-bottom: 1px solid #ddd;

  @media ${theme.breakpoints.md} {
    display: none;
  }
`

const MobileNavButton = styled.button`
  display: inline-block;
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
`

const Nav = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  background: ${theme.colors.neutral[50]};
  box-shadow: 10px 3px 15px rgba(0, 0, 0, 0.05);
`

const NavItem = styled.a<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding-left: 16px;
  padding-right: 16px;
  color: ${theme.colors.neutral[900]};
  text-decoration: none;
  border-bottom: 2px solid transparent;

  &:hover {
    color: ${theme.colors.purple[500]};
  }

  ${(props) =>
    props.active &&
    css`
      font-weight: 600;
      color: ${theme.colors.purple[500]};
      border-bottom-color: ${theme.colors.purple[500]};
    `}

  @media ${theme.breakpoints.md} {
    height: 87px;
    width: auto;
  }
`

const Sidebar = styled.div<{ open?: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 306px;
  padding-top: 24px;
  padding-bottom: 24px;
  flex-direction: column;
  background: ${theme.colors.neutral[50]};
  box-shadow: 10px 3px 15px rgba(0, 0, 0, 0.05);
  z-index: ${theme.zIndex.sidebar};

  @media ${theme.breakpoints.md} {
    right: initial;
    display: flex;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

const LogoImg = styled.img`
  margin-right: 8px;
`

const LogoText = styled.h4`
  margin: 0;
  font-size: ${theme.fontSizes.lg}px;
  color: ${theme.colors.neutral[900]};
`

const UserInfo = styled.div`
  margin-bottom: 24px;
  text-align: center;
`

const UserInfoAvatar = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
`

const UserInfoWelcome = styled.h4`
  margin: 0;
  margin-bottom: 8px;
`

const UserInfoSubtext = styled.div`
  margin-bottom: 8px;
  font-size: ${theme.fontSizes.sm}px;
  color: ${theme.colors.neutral[500]};
`

const UserInfoPlan = styled.div`
  color: ${theme.colors.neutral[500]};
`

const UserInfoPlanStatus = styled.span`
  color: ${theme.colors.green[500]};
`

const PlanButton = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
`

const SidebarMenu = styled.div<{ push?: boolean }>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.push &&
    css`
      margin-top: auto;
    `}
`

const SidebarMenuItem = styled.a<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 0;
  padding-left: 40px;
  text-decoration: none;
  color: ${theme.colors.neutral[500]};

  &:hover {
    color: ${theme.colors.purple[500]};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${theme.colors.purple[500]};
      font-weight: 500;
    `}
`

const SidebarMenuItemIcon = styled.div`
  margin-right: 16px;
  width: 24px;
`

const SidebarMenuItemText = styled.div`
  font-size: ${theme.fontSizes.lg}px;
`

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
